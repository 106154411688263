
import { Vue, Prop, Watch, Component } from "vue-property-decorator";

@Component
export default class ComplexEntriesModal extends Vue {
  @Prop({ default: false }) modalShow!: boolean;
  @Prop({ type: Number }) id!: Number;
  @Prop({ type: Array, default: () => [] }) side_tran_entry!: any[];
  @Prop({ type: Object, default: () => { } }) side_tran!: any;
  @Prop({ default: false }) message!: boolean;
  @Prop({ default: false }) type!: string;
  @Prop({ default: false }) messageArray!: [];

  showModal!: boolean;
  data() {
    return {
      showModal: this.modalShow,
    };
  }
  get modalSize() {
    return this.type === 'success' ? 'xl' : 'md';
  }

  @Watch("modalShow")
  onModalShowChanged(newValue: boolean, oldValue: boolean) {
    this.showModal = this.modalShow;
  }
  close() {
    this.$emit("close-modal");
  }

  // onRow(item: any) {
  //     this.retrieve(item)
  //     console.log(this.id)
  // }
}
