
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import SidebarFinance from "@/components/sidebar-finance.vue"; // @ is an alias to /src
import BuiltinService from "@/services/buitin-service";
import ProjectService from "@/services/project-service";
import accountService from "@/services/account-service";
import ComplexEntriesModal from "@/components/complex-entries-modal.vue";
import ReportService from "@/services/reports-service";
import utils from "@/utils/utils";

@Component({
  components: {
    Header,
    SidebarFinance,
    ComplexEntriesModal,
  },
})
export default class ComplexEntry extends Vue {
  public items = [
    {
      identity: 0,
      account: "",
      transaction: "",
      account_title: "",
      account_code: "",
      cost_center: "",
      cost_center_support: 0,
      description: "",
      debit: 0.0,
      credit: 0.0,
      amount: "",
      reference_no: "",
      po_no: "",
      request_no: "",
      comments: "",
      disableDelete: false,
    },
  ];
  selected_project: any = {};
  public document_file: any = null;
  option_accounts: any = [];
  branch_options = [];
  options_project: any = [];
  trans: any = {};
  auth: any = {};
  sum_d: number = 0;
  sum_c: number = 0;
  total = 0.0;
  transaction = "";
  transaction_code = 0;
  sum_transaction = 0;
  index = 0;
  side_tran_entry: any = [];
  side_tran: any = {};
  all_cost_centers: any = [];
  error: any = "";
  search_cost_centers(param: string) {
    ProjectService.getAllCostCentersMasters(param).then((response) => {
      this.all_cost_centers = response.data.result;
      // console.log(response.data);
    });
  }

  getaccounts() {
    BuiltinService.getauth()
      .then((response) => {
        this.auth = response.data[0];
      })
      .catch((e) => {
        console.log(e);
      });
    BuiltinService.getproject()
      .then((response) => {
        this.options_project = response.data;
      })
      .catch((e) => {
        console.log(e);
      });
    // accountService.getAccounts()
    // .then((response) => {
    //   this.option_accounts = response.data.result;
    //   })
    // .catch((e) => {
    //   console.log(e);
    // });
  }
  previousDebit = 0;
  previousCredit = 0;
  addRow() {
    this.items.push({
      identity: this.items.length + 1,
      account: "",
      transaction: "",
      account_code: "",
      account_title: "",
      cost_center: "",
      cost_center_support: 0,
      description: "",
      debit: this.previousDebit,
      credit: this.previousCredit,
      amount: "",
      reference_no: "",
      po_no: "",
      request_no: "",
      comments: "",
      disableDelete: false,
    });
    for (let i = 1; i < this.items.length; i++) {
      if (
        this.items[i].debit === this.items[i - 1].credit &&
        this.items[i].credit === this.items[i - 1].debit
      ) {
        this.items[i].disableDelete = true;
      }
    }
    this.previousDebit = 0;
    this.previousCredit = 0;
    this.updateTotal();
  }

  changeProject(a: any) {
    ProjectService.get_list_by_id(a)
      .then((response) => {
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  save() {
    this.error = "";
    const error_list = utils.validateArray(this.items, ['account', 'account_code', 'reference_no', 'request_no', 'po_no', 'description',]);
    if (error_list.length > 0 || !this.selected_project || Object.keys(this.selected_project).length === 0) {
      this.error = utils.generateErrorMessage(error_list.concat(!this.selected_project || Object.keys(this.selected_project).length === 0 ? 'Project' : []));
    }
    else {
      let sum_c = 0;
      let sum_d = 0;
      try {

        for (var i = 0; i < this.items.length; i++) {
          sum_c += +this.items[i]["credit"];
        }
        for (var i = 0; i < this.items.length; i++) {
          sum_d += +this.items[i]["debit"];
        }

        // for (var i = 0; i < this.items.length; i++) {
        //   sum_c += (+this.items[i]['credit']);
        //   sum_d += (+this.items[i]['debit']);

        // this.sum_transaction = sum_c - sum_d
        // if (!this.selected_project) {
        //   this.showModal("Please select the Project", "error");
        // }


        if (sum_c == sum_d) {
          this.trans["items"] = this.items;
          this.trans["trans_type"] = "Entry";
          this.trans["branch"] = this.selected_project;
          this.trans["code"] = "0" + (this.items.length + 1).toString();
          accountService
            .getTransaction(this.trans, this.document_file)
            .then((response) => {
              this.trans = {};
              this.items = [];
              this.document_file = null;
              this.selected_project = {};
              this.index = 0;
              this.addRow();
              // this.transaction = 'Transaction Generated ' + response.data.code;
              // alert(this.transaction)
              this.transaction = response.data.code;
              this.transaction_code = response.data.code;
              // alert(this.transaction)
              this.showModal("Transaction Generated Successfully", "success");
              this.retrieve();
              this.error = "";
            })
            .catch((e) => {
              console.log('Unexpected error:', e);
              // this.showModal('An unexpected error occurred', "error");
            });
        }
        // else if (sum_d === 0 || sum_c === 0) {
        //   this.showModal("Debit or Credit may not be 0", "error");
        // }
        // else if (sum_c === 0) {
        //   this.showModal("Credit may not be 0", "error");
        // }
        else {
          // alert('Sum must be equal')
          this.showModal("Sum must be equal", "error");
        }
      } catch (error) {
        console.log('Unexpected error:', error);
        this.showModal('An unexpected error occurred', "error");
      }
    }
  }
  retrieve() {
    ReportService.get_transaction_id(this.transaction_code)
      .then((response) => {
        this.side_tran = response.data.transaction[0];
        this.side_tran_entry = response.data.entry;
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  modalShow = false;
  modalType = "";
  modalMessage = "";
  modalErrorMessage = [];
  errorMessages: any = [];

  closeModal() {
    this.modalShow = false;
    this.modalMessage = "";
    this.modalType = "";
    this.errorMessages = [];
  }
  showModal(message: string, type: "success" | "error") {
    this.modalShow = true;
    this.modalMessage = message;
    this.modalType = type;
  }
  showModalErrors(message: [], type: "errorArray") {
    this.modalShow = true;
    this.modalErrorMessage = message;
    this.modalType = type;
  }
  get_parent_account(data: any) {
    // console.log(data);
    this.items[this.index].account_title = data.title;
    this.items[this.index].account_code = data.code;
    this.items[this.index].account = data.id;
    this.items[this.index].cost_center_support = data.cost_center_support;
    this.option_accounts = [];
  }
  get_cost_centers(data: any) {
    this.items[this.index].cost_center = data.title;
    this.all_cost_centers = [];
  }

  search_parent_account(title: any, index: any) {
    this.index = index;
    if (title.length > 2) {
      accountService
        .getAccountMasterExclSeparated(title)
        .then((response) => {
          this.option_accounts = response.data.result;
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (title.length == 0) {
      this.option_accounts = [];
    }
  }

  mounted() {
    let current = new Date();
    let start_date = `${current.getFullYear()}-${current.getMonth() + 1
      }-${current.getDate()}`;
    this.trans["date"] = start_date;
    this.getaccounts();
  }
  deleteRow(index: any) {
    this.sum_debit -= +this.items[index].debit || 0;
    this.sum_credit -= +this.items[index].credit || 0;
    this.items.splice(index, 1);
    this.autoDebitCredit();
  }
  clearRow(index: any) {
    const item = this.items[index];

    this.sum_debit -= +this.items[0].debit || 0;
    this.sum_credit -= +this.items[0].credit || 0;
    item.account_title = "";
    item.account_code = "";
    item.cost_center = "";
    item.debit = 0.0;
    item.credit = 0.0;
    item.reference_no = "";
    item.request_no = "";
    item.po_no = "";
    item.description = "";
    this.autoDebitCredit();
  }
  sum_debit = 0;
  sum_credit = 0;
  updateTotal() {
    this.sum_debit = 0;
    this.sum_credit = 0;

    for (var i = 0; i < this.items.length; i++) {
      this.sum_debit += +this.items[i].debit || 0;
      this.sum_credit += +this.items[i].credit || 0;
    }

    // console.log("Total sum of debit:", this.sum_debit);
    // console.log("Total sum of credit:", this.sum_credit);
  }
  autoDebitCredit() {
    for (var i = 0; i < this.items.length; i++) {
      this.previousDebit = this.items[i].credit;
      this.previousCredit = this.items[i].debit;
    }
    this.updateTotal()
  }
}
